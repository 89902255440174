@import url(./fonts.css);
@import url(./variables.css);

body,
html {
    overflow-x: hidden;
}

* {
    color: var(--black);
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
}

.three-js-badge {
    height: calc(100vh - 550px);
}

.para {
    &::before {
        content: "";
        display: inline-flex;
        min-width: 50px;
    }
}

.min-height-100 {
    min-height: 100vh;
}

.min-height-70 {
    min-height: 70vh;
}

.min-height-40 {
    min-height: 40vh;
}

.top--section {
    background: var(--yellow-lt);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-jumbotron-hero {
    padding: 0px;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;

    h1 {
        margin: 0 auto;
        color: var(--black);
        font-family: 'PT Serif', serif;
        font-size: 62px;
        font-weight: 400;
        letter-spacing: -1px;
        line-height: 1.25;
        text-align: center;
        position: relative;

        .bigletter {
            font-size: 150px;
            position: absolute;
            top: -59px;
            left: 66px;
            font-weight: 700;
            z-index: -1;
            color: var(--white);
        }

        .smallletter {
            font-size: 20px;
            font-weight: 500;
            display: block;
        }

        i.fa {
            font-size: 40px;
            color: var(--dark-lt);
        }
    }

    .sub-line {
        font-size: 16px;
        text-align: center;
        max-width: 60%;
        margin: 20px auto;
        font-weight: 400;
    }

    .card {
        width: 100%;
        background: transparent;
        border: none;

        .image {
            width: 100%;
            position: relative;

            .btn-section {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                z-index: 99;
                width: 100px;
                height: 100px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                background: url(/assets/images/blogs/card-btn.png);
                background-size: cover;

                .btn {
                    width: 50%;
                    height: 50%;
                    background: var(--black);
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15%;
                    margin-right: 15%;

                    svg {
                        fill: var(--white);
                    }
                }
            }

            img {
                width: 100%;
            }
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 1%, transparent 99%);

            .card-title {
                font-size: 18px;
                font-weight: 600;
                font-family: "PT Serif", serif;
                color: var(--white);
                margin-bottom: 10px;
            }

            .card-text {
                font-size: 16px;
                font-weight: 400;
                color: var(--white);
            }

            .card-date {
                font-size: 14px;
                font-weight: 400;
                color: var(--white);
                margin-bottom: 10px;

                span {
                    color: var(--white);
                    text-transform: lowercase;
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 5px;

                    &::after {
                        content: "";
                        display: inline-flex;
                        width: 5px;
                        height: 5px;
                        background: var(--white);
                        border-radius: 10px;
                    }
                }
            }

            .tags {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                column-gap: 15px;

                .tag {
                    border: 1px solid var(--white);
                    color: var(--white);
                    font-size: 12px;
                    padding: 5px 10px;
                    border-radius: 20px;
                    min-width: 100px;
                    text-align: center;
                }
            }
        }
    }

    .intro-logos {
        display: grid;
        align-items: center;
        width: 100%;
        grid-auto-columns: min-content;
        grid-auto-flow: column;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px 0;

        .intro-trusted-by {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 1;
            text-transform: uppercase;
            white-space: nowrap;
        }

        svg {
            width: 120px;
            height: 60px;
        }
    }
}

.home-client-marquee-grid {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
        -webkit-transition-timing-function: linear !important;
        transition-timing-function: linear !important;
        position: relative;

        .client-marquee-item {
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            text-decoration: none;
            width: 12%;

            &::before {
                content: "";
                background: rgba(0, 0, 0, 0.4);
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9999;
            }

            img {
                width: 100%;
            }

            .client-marquee-item-tags {
                list-style: none;
                padding: 0;
                margin: 0;
                display: grid;
                grid-auto-columns: min-content;
                grid-auto-flow: column;
                gap: 8px;
                margin-top: 4px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 15px;
                z-index: 9999;

                .client-marquee-item-tag {
                    display: flex;
                    -ms-flex-align: center;
                    align-items: center;
                    height: 24px;
                    padding: 0 9px 2px;
                    color: var(--white);
                    background: var(--black);
                    border-radius: 10px;
                    font-size: 11px;
                    white-space: nowrap;
                }
            }
        }
    }
}

.home-about-us-short {
    padding-top: 70px;
    padding-bottom: 30px;

    .heading {
        padding: 30px;

        .main-title {
            color: var(--black);
            font-family: "PT Serif", serif;
            font-size: 50px;
            font-weight: 400;

            span {
                font-weight: 600;
                display: block;
            }
        }
    }

    .info {
        max-width: 70%;

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: justify;
        }
    }

    .banner-section {
        column-gap: 30px;
        margin-top: 30px;

        .card {
            text-decoration: none;
            border: none;
            position: relative;
            cursor: default;

            .btn-section {
                content: "";
                position: absolute;
                top: 0px;
                right: 0px;
                z-index: 99;
                width: 90px;
                height: 90px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                background: url(/assets/images/services/card-btn.png);
                background-size: cover;

                .btn {
                    width: 50%;
                    height: 50%;
                    background: var(--black);
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 15%;
                    margin-right: 15%;

                    svg {
                        fill: var(--white);
                    }
                }
            }

            img {
                height: 100%;
                border-radius: 20px;
            }

            &.rect {
                min-width: calc(((100%/3)*2) - 20px);
            }
        }
    }
}

.random-blogs-list {
    padding-bottom: 30px;

    .heading {
        padding: 30px 0;

        .main-title {
            color: var(--black);
            font-family: "PT Serif", serif;
            font-size: 50px;
            font-weight: 400;

            span {
                font-weight: 600;
            }
        }
    }

    .banner-section {
        column-gap: 30px;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 30px;

        .card {
            text-decoration: none;
            border: none;
            min-width: calc((100%/4) - 20px);
            max-width: calc((100%/4) - 20px);

            .image {
                width: 100%;
                position: relative;

                .btn-section {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    z-index: 99;
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    background: url(/assets/images/services/card-btn.png);
                    background-size: cover;

                    .btn {
                        width: 50%;
                        height: 50%;
                        background: var(--black);
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 15%;
                        margin-right: 15%;

                        svg {
                            fill: var(--white);
                        }
                    }
                }

                img {
                    width: 100%;
                    border-radius: 20px;
                }
            }

            .card-body {
                .card-date {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--black);
                    margin-bottom: 10px;

                    span {
                        color: var(--black);
                        text-transform: lowercase;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        column-gap: 5px;

                        &::after {
                            content: "";
                            display: inline-flex;
                            width: 5px;
                            height: 5px;
                            background: var(--black);
                            border-radius: 10px;
                        }
                    }
                }
                .card-title {
                    font-size: 16px;
                    color: var(--black);
                    font-weight: 600;
                    font-family: "PT Serif", serif;
                    text-transform: uppercase;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .card-text {
                    font-size: 16px;
                    color: var(--black);
                    font-weight: 400;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}
.home-service-us-short {
    padding-bottom: 30px;

    .heading {
        padding: 30px;

        .main-title {
            color: var(--black);
            font-family: "PT Serif", serif;
            font-size: 50px;
            font-weight: 400;

            span {
                font-weight: 600;
                display: block;
            }
        }
    }

    .info {
        max-width: 70%;

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: justify;
        }
    }

    .banner-section {
        column-gap: 30px;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 30px;

        .card {
            text-decoration: none;
            border: none;
            min-width: calc((100%/3) - 20px);
            max-width: calc((100%/3) - 20px);

            .image {
                width: 100%;
                position: relative;

                .btn-section {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    z-index: 99;
                    width: 25%;
                    height: 25%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    background: url(/assets/images/services/card-btn.png);
                    background-size: cover;

                    .btn {
                        width: 50%;
                        height: 50%;
                        background: var(--black);
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 15%;
                        margin-right: 15%;

                        svg {
                            fill: var(--white);
                        }
                    }
                }

                img {
                    width: 100%;
                    border-radius: 20px;
                }
            }

            .card-body {
                .card-title {
                    font-size: 16px;
                    color: var(--black);
                    font-weight: 600;
                    font-family: "PT Serif", serif;
                    text-transform: uppercase;

                    &.odd {
                        background: var(--white);
                        padding: 10px 40px;
                        margin: -38px auto;
                        z-index: 9999999;
                        position: relative;
                        width: fit-content;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }
                }

                .card-text {
                    font-size: 16px;
                    color: var(--black);
                    font-weight: 400;
                }
            }
        }
    }
}

.home-testimonial {
    padding-bottom: 70px;

    .heading {
        padding: 30px;

        .main-title {
            color: var(--black);
            font-family: "PT Serif", serif;
            font-size: 50px;
            font-weight: 400;

            span {
                font-weight: 600;
                display: block;
            }
        }
    }

    .reviews {
        -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
        margin-bottom: 15px;
        width: 100%;
        overflow: hidden;

        .swiper-wrapper {
            -webkit-transition-timing-function: linear !important;
            transition-timing-function: linear !important;

            .box {
                background: var(--white-lt);
                padding: 20px;
                border-radius: 10px;

                i.fa.fa-quote-left {
                    background: var(--black);
                    display: flex;
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    color: var(--white);
                    border-radius: 5px;
                    margin-bottom: 10px;
                }

                .msg {
                    .title {
                        font-size: 18px;
                        font-weight: 600;
                        color: var(--black);
                        margin-bottom: 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 10px;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }

                .header {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 15px;
                    margin-top: auto;

                    .avatar {
                        background: var(--black);
                        display: flex;
                        width: 40px;
                        height: 40px;
                        font-size: 14px;
                        text-transform: uppercase;
                        justify-content: center;
                        align-items: center;
                        color: var(--white);
                        border-radius: 40px;
                    }

                    .person {
                        .name {
                            font-size: 14px;
                        }

                        .rate {
                            .fa {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

}

.about-company-photos {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    z-index: 99999;

    &::before {
        content: "";
        background: var(--yellow-lt);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70%;
        z-index: -1;
    }

    h4 {
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 40px;
        text-transform: lowercase;
        font-family: "PT Serif", serif;
        margin-bottom: 40px;
    }

    .office-gallery {
        column-gap: 25px;

        .card {
            background: transparent;
            border-radius: 0;
            border: none;
            min-width: 60%;
        }
    }
}

.about-our-strengths {
    h2 {
        font-size: 50px;
        font-family: "PT Serif", serif;
        font-weight: 600;
        margin-bottom: 100px;
    }

    .box {
        padding: 50px;
        padding-left: 15px;

        &:nth-child(odd) {
            padding-left: 50px;
        }

        h4 {
            font-size: 26px;
            font-family: "PT Serif", serif;
            font-weight: 500;
            margin-bottom: 30px;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            color: var(--dark-lt);
        }
    }
}

.contact-form {
    form {
        padding-bottom: 30px;

        h4 {
            font-size: 18px;
            margin-bottom: 20px;
            font-family: "PT Serif", serif;
        }

        .btn-check-options {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 20px;

            input {
                display: none;

                &:checked {
                    &+.btn {
                        background: var(--black);
                        color: var(--white);
                    }
                }
            }

            .btn {
                border-radius: 50px;
                background: none;
                color: var(--black);
                border: 2px solid var(--black);
                padding: 10px 25px;
                font-size: 14px;

                &:hover {
                    background: var(--dark-lt);
                    color: var(--white);
                }
            }
        }

        .form-group {
            margin-bottom: 20px;

            label {
                font-size: 18px;
                font-family: "PT Serif", serif;
            }

            textarea.form-control,
            input.form-control {
                min-height: 60px;
                border-radius: 0;
                background: transparent;
                border: none;
                box-shadow: none !important;
                outline: none;
                color: var(--black);
                font-size: 18px;
                border-bottom: 2px solid var(--white-lt);
                padding-left: 0;

                &:focus {
                    border-bottom: 2px solid var(--dark-lt);
                }
            }
        }

        .btn-submit {
            border-radius: 50px;
            border: 2px solid var(--dark-lt);
            padding: 10px 25px;
            font-size: 14px;
            background: var(--dark-lt);
            color: var(--white);

            svg {
                fill: var(--white);
            }

            &:hover {
                background: var(--black);
            }
        }
    }
}

.blogs-detail-view {
    margin-top: 60px;
    margin-bottom: 60px;

    &>img {
        width: 100%;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        line-height: 30px;

        &::selection {
            color: var(--black);
            background: var(--yellow-lt);
        }

        span {
            &.bigword {
                float: left;
                font-size: 60px;
                padding: 0px 20px;
                line-height: 60px;
                font-weight: 700;
                font-family: "PT Serif", serif;
            }
        }

        a {
            font-family: "PT Serif", serif;
            color: var(--blue-lt);
            text-decoration: none;
            font-weight: 700;
        }
    }

    h3 {
        font-size: 20px;
        margin: 30px 0;
        font-family: "PT Serif", serif;
        font-weight: 700;
    }

    ol li,
    ul li{
        font-size: 14px;
    }
    img.img-thumbnail{
        max-width: 50%;
        margin: 20px;
    }
    a{
        font-family: "PT Serif", serif;
        color: var(--blue-lt);
        text-decoration: none;
        font-weight: 700;
        &:hover{
            color: var(--yellow-lt);
        }
    }
    .ads-banner {
        background: var(--black);
        border-radius: 0;
        border: none;
        margin-top: 40px;
        margin-bottom: 40px;
        position: relative;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: -100%;
            background: var(--black);
            z-index: -1;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 60px;

            .card-title {
                color: var(--white);
                font-size: 22px;
                font-family: "PT Serif", serif;
                font-weight: 500;
            }

            .card-text {
                color: var(--white);
                font-size: 16px;
                font-weight: 300;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }

            .card-link {
                color: var(--white);
                text-decoration: none;
                font-size: 14px;
                text-transform: uppercase;
                cursor: pointer;
            }
        }

        img {
            margin: -30px 0px;
            margin-right: 30px;
            border-radius: 10px;
            max-width: 20%;
        }
    }

}

.blogs-pagination-listing {
    margin-top: 60px;
    margin-bottom: 60px;

    .card-group {
        column-gap: 20px;

        .card {
            border-radius: 0;
            border: none;
            min-width: calc(25% - 15px);
            max-width: calc(25% - 15px);

            .image {
                width: 100%;
                position: relative;

                .btn-section {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    z-index: 99;
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    background: url(/assets/images/services/card-btn.png);
                    background-size: cover;

                    .btn {
                        width: 50%;
                        height: 50%;
                        background: var(--black);
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 15%;
                        margin-right: 15%;

                        svg {
                            fill: var(--white);
                        }
                    }
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                }
            }

            .card-body {
                padding: 15px 0;

                .card-title {
                    font-size: 18px;
                    font-weight: 600;
                    font-family: "PT Serif", serif;
                    color: var(--black);
                    margin-bottom: 10px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .card-text {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--black);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .card-date {
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--black);
                    margin-bottom: 10px;

                    span {
                        color: var(--black);
                        text-transform: lowercase;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        column-gap: 5px;

                        &::after {
                            content: "";
                            display: inline-flex;
                            width: 5px;
                            height: 5px;
                            background: var(--black);
                            border-radius: 10px;
                        }
                    }
                }

                .tags {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    column-gap: 15px;
                    
                    .tag {
                        border: 1px solid var(--black);
                        color: var(--black);
                        font-size: 12px;
                        padding: 2px 10px;
                        border-radius: 10px;
                        min-width: 80px;
                        text-align: center;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    nav {
        margin-top: 30px;

        .pagination {
            column-gap: 10px;

            .page-item {
                .page-link {
                    font-size: 14px;
                    font-weight: 600;
                    color: var(--black);
                    box-shadow: none;
                    border-radius: 0;

                    &.active {
                        background: var(--black);
                        color: var(--white);
                        border-color: var(--white);
                    }
                }

                &:first-child {
                    margin-right: auto;
                }

                &:last-child {
                    margin-left: auto;
                }

                &:first-child,
                &:last-child {
                    .page-link {
                        border: none;
                        display: flex;
                        column-gap: 10px;
                    }
                }

                &.disabled {
                    .page-link {
                        background: var(--white);
                    }
                }
            }
        }
    }
}
.portfolio-items{
    .block{
        column-count: 3;
        flex-wrap: wrap;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-columns: 1fr;
        align-content: flex-start;
        justify-content: space-between;
        column-gap: 3.69%;
        display: flex;
        margin-top: 60px;
        margin-bottom: 60px;
        .portfolio-item {
            width: 30%;
            text-align: left;
            margin-top: 0;
            &:nth-child(3n+1),
            &:nth-child(3n+3) {
                margin-top: 60px;
            }
            .portfolio-link {
                color: var(--black);
                font-size: 18px;
                line-height: 24px;
                text-decoration: none;
                position: relative;
                img {
                    max-width: 100%;
                    vertical-align: middle;
                    display: inline-block;
                    border-radius: 15px;
                }
                .portfolio-flex {
                    justify-content: center;
                    align-items: flex-start;
                    margin-top: 16px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;
                    .portfolio-title {
                        margin-top: 0;
                        margin-bottom: 0;
                        font-size: 18px;
                        line-height: 24px;
                    }
                    .tag {
                        color: var(--black);
                        text-transform: uppercase;
                        background-color: var(--yellow-lt);
                        border-radius: 50px;
                        margin-right: 20px;
                        padding: 6px 12px;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        display: inline-block;
                        font-family: "PT Serif", serif;
                    }
                }
            }
        }
    }
}
.thankyoucontent{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: var(--black);
    align-items: center;
    justify-content: center;
    .fa{
        color: var(--white);
        font-size: 180px;    
    }
    h1{
        font-family: "PT Serif", serif;
        font-size:40px;
        font-weight: 400;
        color: var(--white);
        margin:30px 0;
    }
    p{
        margin:0;
        font-size:16px;
        color:var(--white);
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
    }    

    .go-home {
        font-family: "PT Serif", serif;
        border: none;
        color: var(--white);
        white-space: nowrap;
        padding: 8px 20px;
        border-radius: 0px;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        z-index: 11;
        transition: all 0.2s;
        text-decoration: none;
        margin-top: 40px;
        .fa{
            font-size: 18px;
            margin-left: 15px;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0px;
            right: 0px;
            border: 1px solid var(--white-lt);
            background: var(--black);
            z-index: -1;
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0px;
            right: 0px;
            border: 1px solid var(--white-lt);
            z-index: -2;
        }

        &:hover {
            transform: translate(-4px, -4px);

            &::after {
                transform: translate(4px, 4px);
            }
        }
    }
}
.footer-connect-us {
    background: var(--black);
    overflow: hidden;

    .design {
        .design-first {
            top: -10px;
            left: -15px;
        }

        .design-last {
            top: -10px;
            right: -15px;
        }

        svg {
            path {
                fill: var(--white-lt);
                fill-opacity: 0.2;
            }
        }
    }

    .middle {
        padding-top: 150px;
        padding-bottom: 150px;

        h2 {
            font-size: 45px;
            font-family: "PT Serif", serif;
        }

        .btn {
            color: var(--black);
            border-color: var(--white-lt);
            background-color: var(--white-lt);
            font-size: 16px;
            padding: 10px 20px;
            font-weight: 500;
            font-family: "PT Serif", serif;
        }
    }
}

.footer {
    background: var(--white-lt);
    padding: 50px 15px;

    h2 {
        font-size: 50px;
        font-family: "PT Serif", serif;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .mailto {
        margin-bottom: 50px;

        a {
            font-size: 16px;
            column-gap: 10px;
            text-decoration: none;

            svg {
                width: 28px;
                height: 28px;
            }

            &:hover {
                svg {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .address {
        .title {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
        }

        a {
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            color: var(--dark-lt);
            display: block;
            line-height: 26px;
        }
    }

    .nav {
        flex-direction: column;

        .links {
            font-size: 14px;
            color: var(--black);
            text-decoration: none;
            font-weight: 600;
            margin-bottom: 15px;
            white-space: nowrap;
        }
    }
}

@media (max-width: 1400px) {
    .home-jumbotron-hero {
        h1 {
            font-size: 4rem;
            line-height: 5rem;

            span,
            i {
                font-size: 70px;
            }

            .bigletter {
                top: -5px;
                left: 104px;
                font-size: 110px;
            }
        }
    }

    .home-about-us-short {
        padding-top: 60px;
        padding-bottom: 60px;

        .heading {
            .main-title {
                font-size: 40px;
            }
        }

        .info {
            max-width: 60%;

            p {
                font-size: 16px;
            }

            .industries {
                max-width: 100%;

            }
        }
    }

    .home-service-us-short {
        padding-bottom: 60px;

        .heading {
            .main-title {
                font-size: 40px;
            }
        }

        .info {
            p {
                font-size: 16px;
            }
        }
    }
    .random-blogs-list {
        padding-bottom: 60px;

        .heading {
            .main-title {
                font-size: 40px;
            }
        }
    }

    .home-testimonial {
        padding-bottom: 60px;

        .heading {
            .main-title {
                font-size: 40px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .home-jumbotron-hero {
        h1 {
            font-size: 3rem;
            line-height: 4rem;

            span,
            i {
                font-size: 50px;
            }

            .bigletter {
                display: none;
            }
        }

        .total-client {
            flex-direction: column;
            align-items: center;
            margin-top: 15px;

            .sub-line {
                padding: 15px;
                max-width: 100%;
                text-align: center;
            }
        }
    }

    .about-company-photos {
        padding-top: 70px;
        padding-bottom: 70px;

        h2 {
            font-size: 30px;
        }
    }

    .about-our-strengths {
        h2 {
            font-size: 40px;
            margin-bottom: 70px;
        }

        .box {
            padding: 40px;

            &:nth-child(odd) {
                padding-left: 40px;
            }
        }
    }
}

@media (min-width: 992px) {
    .top-bar {
        z-index: 999999;
        background: var(--yellow-lt);

        .navbar-brand {
            margin: 10px 0;

            span {
                font-family: 'Pacifico', cursive;
                font-size: 22px;
                color: var(--black);
            }

            img {
                display: none;
            }

            sup {
                font-size: 16px;
                color: var(--dark-lt);
                margin-left: 5px;
            }

            i {
                font-size: 16px;
                font-family: "Pacifico", cursive;
                font-style: normal;
            }
        }

        .navbar-nav {
            &.menus {
                margin-left: 50px;

                .nav-item {
                    .nav-link {
                        z-index: 9999;
                        color: var(--dark);
                        font-weight: 600;
                        font-size: 14px;
                        padding: 0px;
                        max-height: 44px;
                        position: relative;
                        overflow: hidden;

                        &::before,
                        span {
                            padding: 10px 20px;
                            justify-content: flex-start;
                            display: flex;
                            white-space: nowrap;
                            -webkit-transition: transform .5s ease-out .1s;
                            transition: transform .5s ease-out .1s;
                            -webkit-filter: blur(0);
                            filter: blur(0);
                            cursor: pointer;
                            font-family: "mona-sans";
                            font-weight: 600;
                        }

                        &::before {
                            content: attr(data-hover-text);
                            color: var(--dark);
                        }

                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: var(--dark-lt);
                            border: none;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            -webkit-transform: translate(-110%, -50%);
                            -ms-transform: translate(-110%, -50%);
                            transform: translate(-110%, -50%);
                            -webkit-transition: transform .5s ease;
                            transition: transform .5s ease;
                        }

                        &.active,
                        &:hover {
                            &::after {
                                -webkit-transform: translate(102%, -50%);
                                -ms-transform: translate(102%, -50%);
                                transform: translate(102%, -50%);
                            }

                            &::before {
                                -webkit-transform: translateY(-44px);
                                -ms-transform: translateY(-44px);
                                transform: translateY(-44px);
                            }

                            span {
                                -webkit-transform: translateY(-40px);
                                -ms-transform: translateY(-40px);
                                transform: translateY(-40px);
                            }

                        }
                    }

                    &.dropdown {
                        position: unset;

                        &.active,
                        &:hover {

                            .dropdown-menu.show,
                            .dropdown-menu {
                                display: block;
                                top: 66px;
                                margin-top: var(--bs-dropdown-spacer);
                                z-index: 1111;
                                transition: top 0.3s;
                                transition-delay: 0.7s;
                                min-height: calc(100vh - 68px);

                                .container {
                                    .drop-items {
                                        .drop-item {
                                            -webkit-transform: rotateY(0);
                                            transform: rotateY(0);
                                            transition: .3s ease 0s;
                                            opacity: 1;
                                            transition-delay: 1s;
                                        }
                                    }
                                }
                            }
                        }

                        .dropdown-menu {
                            background: var(--yellow-lt);
                            border: none;
                            --bs-dropdown-min-width: 100%;
                            left: 0;
                            display: block;
                            top: -1000px;
                            transition: top 0.8s;
                            z-index: -1;

                            &.show {
                                display: none;
                            }

                            .container {
                                .header {
                                    padding: 20px 15px;
                                    margin-bottom: 15px;

                                    .title {
                                        font-family: "mona-sans";
                                        font-size: 14px;
                                        font-weight: 600;
                                        padding: 10px;
                                        text-transform: uppercase;
                                    }

                                    .para {
                                        font-size: 14px;
                                        font-weight: 500;
                                        padding: 10px;
                                        text-transform: lowercase;
                                    }
                                }

                                .drop-items {
                                    display: flex;
                                    justify-content: center;
                                    align-items: flex-start;
                                    column-gap: 15px;
                                    flex-wrap: wrap;

                                    .drop-item {
                                        display: flex;
                                        flex-direction: column;
                                        max-width: calc(20% - 15px);
                                        min-width: calc(20% - 15px);
                                        justify-content: flex-start;
                                        align-items: center;
                                        margin-bottom: 15px;
                                        text-decoration: none;
                                        -webkit-transform: rotateY(45deg);
                                        transform: rotateY(45deg);
                                        opacity: 0;
                                        transition: opacity 50ms ease .3s, transform 50ms ease .3s;
                                        transition-delay: 0.7s;

                                        .image {
                                            width: 100%;

                                            img {
                                                width: 100%;
                                                border-radius: 5px;
                                            }

                                            &:hover {
                                                img {
                                                    box-shadow: 0 0 0 5px #fff, 0 0 6px 6px rgba(0, 0, 0, 0.09);
                                                    -webkit-transform: scale(.9);
                                                    -ms-transform: scale(.9);
                                                    transform: scale(.9);
                                                }
                                            }
                                        }

                                        .drop-body {
                                            padding: 10px;

                                            .drop-title {
                                                text-transform: capitalize;
                                                font-weight: 600;
                                                font-size: 14px;
                                                font-family: "mona-sans";
                                            }

                                            .drop-text {
                                                font-size: 14px;
                                                font-weight: 500;
                                                text-transform: lowercase;
                                                overflow: hidden;
                                                display: -webkit-box;
                                                -webkit-line-clamp: 2;
                                                -webkit-box-orient: vertical;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.socials {
                column-gap: 10px;

                .nav-item {
                    .nav-link {
                        i {
                            color: var(--black);
                            font-size: 20px;
                        }

                        &.btn {
                            border: none;
                            color: var(--black);
                            white-space: nowrap;
                            padding: 8px 20px;
                            border-radius: 0px;
                            font-size: 14px;
                            font-weight: 600;
                            position: relative;
                            z-index: 11;
                            transition: all 0.2s;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                bottom: 0px;
                                right: 0px;
                                border: 1px solid var(--dark-lt);
                                background: var(--yellow-lt);
                                z-index: -1;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                bottom: 0px;
                                right: 0px;
                                border: 1px solid var(--dark-lt);
                                z-index: -2;
                            }

                            &:hover {
                                transform: translate(-4px, -4px);

                                &::after {
                                    transform: translate(4px, 4px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    body {
        padding-top: 60px;
    }

    .top--section {
        min-height: calc(100vh - 60px);
        justify-content: space-around;

        .container.mt-auto {
            margin-top: unset !important;
        }
    }

    .top-bar {
        position: fixed;
        top: 0;
        left: 0px;
        right: 0px;
        border-bottom: 1px solid #b3b3b3;
        z-index: 9999999;
        background: var(--yellow-lt);

        &.in {
            background: var(--yellow-lt);
        }

        &>.container {
            justify-content: flex-start;
            column-gap: 10px;
            max-width: 100%;
        }

        .navbar-brand {
            margin: 0;

            img {
                display: flex;
                width: 35px;
            }

            span {
                display: none;
            }
        }

        .navbar-toggler {
            padding: 0px;
            border: none;
            box-shadow: none;

            .bar {
                width: 25px;
                height: 2px;
                background-color: var(--black);
                margin: 6px 0;
                transition: 0.4s;
                border-radius: 5px;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                -webkit-transform-origin: 0% 50%;
                -ms-transform-origin: 0% 50%;
                transform-origin: 0% 50%;
                -webkit-transition: 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
                transition: 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);

                &:last-child {
                    width: 18px;
                }
            }

            &:not(.collapsed) {
                .bar1 {
                    transform: translate(4px, 18px) rotate(-45deg);
                }

                .bar3 {
                    transform: translate(4px, -16px) rotate(45deg);
                    width: 25px
                }

                .bar2 {
                    opacity: 0;
                }
            }
        }

        .navbar-collapse {
            width: 100%;
            display: flex;
            justify-content: flex-start !important;
            align-items: flex-start;
            position: fixed;
            top: 61px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99999999;
            background: var(--yellow-lt);
            margin-left: -1000px;
            transition: margin-left 0.5s;
        }

        .navbar-nav.socials {
            flex-direction: row;
            margin-left: auto;

            .nav-item {
                .nav-link {
                    font-size: 12px;
                    padding: 8px;
                    margin: 0px 3px;

                    i {
                        font-size: 20px;
                    }

                    &.btn {
                        display: none;
                    }
                }
            }
        }

        .navbar-collapse.collapsing,
        .navbar-collapse.show {
            margin-left: 0;
            transition: margin-left 0.5s;
            height: calc(100vh - 62px) !important;
            overflow-x: auto;

            .navbar-nav.menus {
                margin: 0;
                width: 100%;

                .nav-item {
                    .nav-link {
                        padding: 8px 15px;

                        span {
                            font-size: 14px;
                            font-family: "mona-sans";
                            font-weight: 600;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0;
                        display: block;
                        padding: 0;
                        border: none;

                        &>.container {
                            max-width: 100%;

                            .header {
                                display: none !important;
                            }

                            .drop-items {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                flex-direction: column;
                                background: var(--yellow-lt);

                                .drop-item {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    text-decoration: none;

                                    .image {
                                        min-width: 80px;
                                        max-width: 80px;

                                        img {
                                            width: 100%;
                                        }
                                    }

                                    .drop-body {
                                        padding-left: 10px;

                                        .drop-title {
                                            text-transform: capitalize;
                                            font-weight: 600;
                                            font-size: 14px;
                                            font-family: "mona-sans";
                                        }

                                        .drop-text {
                                            font-size: 14px;
                                            font-weight: 500;
                                            text-transform: lowercase;
                                            overflow: hidden;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 1;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-jumbotron-hero {
        align-items: center;
        min-height: unset;

        h1 {
            font-size: 2rem;
            line-height: 2.5rem;

            i.fa {
                font-size: 20px;
            }
        }

        .sub-line {
            text-align: justify;
            font-size: 14px;
            font-weight: 500;
            max-width: 100%;
            margin: 10px auto;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .intro-logos {
            grid-auto-rows: -webkit-min-content;
            grid-auto-rows: min-content;
            grid-template-columns: 1fr 1fr;
            row-gap: 20px;
            justify-items: center;
            max-width: 100%;
            grid-auto-flow: row;
            padding: 10px 0;

            .intro-trusted-by {
                grid-column: span 2;
            }
        }
    }

    .home-about-us-short {
        padding-top: 0px;
        padding-bottom: 0px;

        &>.d-flex {
            flex-direction: column !important;
        }

        .banner-section {
            flex-direction: column-reverse !important;
            row-gap: 20px;
            align-items: center;

            .card {
                min-width: 100%;
                max-width: 100%;
                border-radius: 10px;
                overflow: hidden;

                &.rect {
                    min-width: 100%;
                }
            }
        }

        .heading {
            padding: 0;

            .main-title {
                font-size: 30px;
                text-align: center;
            }
        }

        .info {
            max-width: 100%;
            padding-top: 15px;

            p {
                font-size: 14px;
            }
        }
    }

    .home-testimonial {
        padding-bottom: 0px;

        .heading {
            .main-title {
                font-size: 30px;
            }
        }
    }

    .home-service-us-short {
        padding-top: 30px;
        padding-bottom: 0px;

        &>.d-flex {
            flex-direction: column !important;
        }

        .banner-section {
            flex-direction: row !important;
            column-gap: 15px;

            .card {
                min-width: calc((100% / 3) - 10px);
                max-width: calc((100% / 3) - 10px);

                .image {
                    .btn-section {
                        width: 37%;
                        height: 37%;
                    }
                }

                .card-body {
                    .card-title {
                        font-size: 14px;

                        &.odd {
                            padding: 10px 25px;
                            margin: -35px auto;
                        }
                    }

                    .card-text {
                        font-size: 12px;
                    }
                }
            }
        }

        .heading {
            padding: 0;

            .main-title {
                font-size: 30px;
                text-align: center;
            }
        }

        .info {
            max-width: 100%;
            padding-top: 15px;

            p {
                font-size: 14px;
            }
        }
    }
    .random-blogs-list {
        padding-top: 30px;
        padding-bottom: 0px;

        &>.d-flex {
            flex-direction: column !important;
        }

        .banner-section {
            flex-direction: row !important;
            column-gap: 15px;

            .card {
                min-width: calc((100% / 3) - 10px);
                max-width: calc((100% / 3) - 10px);

                .image {
                    .btn-section {
                        width: 37%;
                        height: 37%;
                    }
                }

                .card-body {
                    .card-title {
                        font-size: 14px;
                    }

                    .card-text {
                        font-size: 12px;
                    }
                }
            }
        }

        .heading {
            padding: 0;

            .main-title {
                font-size: 30px;
                text-align: center;
            }
        }
    }

    .footer-connect-us {
        .middle {
            padding-top: 100px;
            padding-bottom: 100px;

            h2 {
                font-size: 35px;
                margin-bottom: 20px !important;
            }
        }
    }

    .about-company-photos {
        padding-top: 50px;

        h2 {
            font-size: 25px;
            margin-bottom: 20px;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .about-our-strengths {
        margin-bottom: 40px;

        h2 {
            font-size: 30px;
            margin-bottom: 50px;
        }

        .box {
            padding: 20px;
            padding-left: 10px;
            padding-bottom: 0px;

            &:nth-child(odd) {
                padding-left: 10px;
            }

            h4 {
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .blogs-pagination-listing {
        .card-group {
            .card {
                min-width: calc((100% / 3) - 15px);
                max-width: calc((100% / 3) - 15px);
            }
        }
    }

    .footer {
        .nav {
            .links {
                padding: 0px 15px;
            }
        }
    }
    .portfolio-items {
        .block{
            column-count: 2;
            row-gap: 30px;
            margin-top: 30px;
            .portfolio-item {
                width: 47%;
                &:nth-child(3n+1), 
                &:nth-child(3n+3) {
                    margin-top: 0px;
                }
                .portfolio-link{
                    .portfolio-flex {
                        .portfolio-title {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                } 
            }
        }
    }
}


@media (min-width: 768px) {}

@media (max-width: 768px) {
    .footer {
        padding: 30px 15px;

        h2 {
            font-size: 30px;
            margin-bottom: 15px;
        }

        .mailto {
            margin-bottom: 20px;
        }

        .sub-details {
            flex-direction: column-reverse;

            .address {
                .title {
                    margin-bottom: 10px !important;
                    line-height: 18px;
                }
            }

            .nav {
                margin-left: unset !important;
                margin-bottom: 20px;
                max-width: 50%;

                .links {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .footer-connect-us {
        .middle {
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                font-size: 28px;
                margin-bottom: 20px !important;
            }

            .btn {
                display: block;
                width: 100%;
            }
        }
    }

    .home-about-us-short {
        .heading {
            .main-title {
                font-size: 20px;
            }
        }
    }

    .home-testimonial {
        .heading {
            padding: 15px;

            .main-title {
                font-size: 20px;
            }
        }

        .reviews {
            .swiper-wrapper {
                .box {
                    i.fa.fa-quote-left {
                        width: 30px;
                        height: 30px;
                        font-size: 14px;
                    }

                    .msg {
                        .title {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }

                        p {
                            font-size: 12px;
                        }
                    }

                    .header {
                        .avatar {
                            width: 30px;
                            height: 30px;
                            font-size: 12px;
                        }

                        .person {
                            .name {
                                font-size: 12px;
                            }

                            .rate {
                                .fa {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .home-service-us-short {
        .banner-section {
            flex-wrap: wrap;

            .card {
                min-width: calc((100% / 2) - 10px);
                max-width: calc((100% / 2) - 10px);

                &.odd {
                    flex-direction: column-reverse;
                }

                .card-body {
                    padding: 10px 5px;

                    .card-title {
                        font-size: 14px;

                        &.odd {
                            padding: 10px 25px;
                            white-space: nowrap;
                        }
                    }

                    .card-text {
                        font-size: 12px;
                    }
                }
            }
        }

        .heading {
            .main-title {
                font-size: 20px;
            }
        }
    }
    .random-blogs-list {
        .banner-section {
            flex-wrap: wrap;

            .card {
                min-width: calc((100% / 2) - 10px);
                max-width: calc((100% / 2) - 10px);

                .card-body {
                    padding: 10px 5px;

                    .card-title {
                        font-size: 14px;
                    }

                    .card-text {
                        font-size: 12px;
                    }
                }
            }
        }

        .heading {
            .main-title {
                font-size: 20px;
            }
        }
    }

    .home-jumbotron-hero {
        padding: 10px;

        h1 {
            font-size: 1.8rem;
            line-height: 2rem;

            span,
            i {
                font-size: 28px;
            }
        }

        .intro-logos {
            row-gap: 5px;

            svg {
                width: 100px;
                height: 50px;
            }
        }

        .card {
            .card-body {
                position: unset;
                background: transparent;

                .card-date,
                .card-date span,
                .card-text,
                .card-title {
                    color: var(--black);
                }

                .card-title {
                    font-size: 16px;
                }

                .card-text {
                    font-size: 14px;
                }

                .tags {
                    .tag {
                        color: var(--black);
                        border-color: var(--black);
                    }
                }
            }
        }
    }

    .about-company-photos {
        padding-top: 30px;
        padding-bottom: 30px;

        h2 {
            font-size: 20px;
            margin-bottom: 15px;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .about-our-strengths {
        h2 {
            font-size: 26px;
            margin-bottom: 30px;
        }

        .box {
            h4 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .contact-form {
        form {
            h4 {
                font-size: 16px;
                margin-bottom: 15px;
            }

            .btn-check-options {
                column-gap: 10px;
                row-gap: 10px;

                .btn {
                    padding: 6px 18px;
                    font-size: 12px;
                }
            }

            .form-group {
                margin-bottom: 15px;

                label {
                    font-size: 14px;
                }

                input.form-control {
                    height: 40px;
                    font-size: 14px;
                }
            }

            .btn-submit {
                padding: 6px 18px;
                font-size: 12px;
            }
        }
    }

    .no-xs-height,
    .min-height-70 {
        min-height: 30vh;
    }

    .blogs-pagination-listing {
        .card-group {
            .card {
                min-width: calc((100% / 2) - 15px);
                max-width: calc((100% / 2) - 15px);
            }
        }
    }

    .blogs-detail-view {
        .ads-banner {
            margin-top: 100px;

            &::after {
                display: none;
            }

            .d-flex {
                flex-direction: column-reverse;

                img {
                    max-width: 50%;
                    margin: -60px auto;
                    margin-bottom: 0px;
                }
            }
        }

        .content {
            padding: 0 15px !important;
        }
    }
    
    .portfolio-items {
        .block{
            column-count: 1;
            margin-bottom: 30px;
            .portfolio-item {
                width: 100%;
                .portfolio-link {
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    .blogs-detail-view{
        img.img-thumbnail{
            max-width: 100%;
            margin: 0px;
        }
    }

}

@media (max-width: 576px) {

    .about-company-photos {
        padding-top: 20px;
        padding-bottom: 20px;

        h2 {
            font-size: 16px;
            margin-bottom: 15px;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 10px;
        }
    }

    .about-our-strengths {
        h2 {
            font-size: 24px;
            margin-bottom: 20px;
        }
    }

    .blogs-pagination-listing {
        padding: 0 15px;

        .card-group {
            .card {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }

    .blogs-pagination-listing {
        .card-group {
            padding: 0 15px;
        }
    }
}