@import url(./fonts.css);
@import url(./variables.css);
body,
html {
  overflow-x: hidden;
}

* {
  color: var(--black);
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

.three-js-badge {
  height: calc(100vh - 550px);
}

.para::before {
  content: "";
  display: inline-flex;
  min-width: 50px;
}

.min-height-100 {
  min-height: 100vh;
}

.min-height-70 {
  min-height: 70vh;
}

.min-height-40 {
  min-height: 40vh;
}

.top--section {
  background: var(--yellow-lt);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-jumbotron-hero {
  padding: 0px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}
.home-jumbotron-hero h1 {
  margin: 0 auto;
  color: var(--black);
  font-family: "PT Serif", serif;
  font-size: 62px;
  font-weight: 400;
  letter-spacing: -1px;
  line-height: 1.25;
  text-align: center;
  position: relative;
}
.home-jumbotron-hero h1 .bigletter {
  font-size: 150px;
  position: absolute;
  top: -59px;
  left: 66px;
  font-weight: 700;
  z-index: -1;
  color: var(--white);
}
.home-jumbotron-hero h1 .smallletter {
  font-size: 20px;
  font-weight: 500;
  display: block;
}
.home-jumbotron-hero h1 i.fa {
  font-size: 40px;
  color: var(--dark-lt);
}
.home-jumbotron-hero .sub-line {
  font-size: 16px;
  text-align: center;
  max-width: 60%;
  margin: 20px auto;
  font-weight: 400;
}
.home-jumbotron-hero .card {
  width: 100%;
  background: transparent;
  border: none;
}
.home-jumbotron-hero .card .image {
  width: 100%;
  position: relative;
}
.home-jumbotron-hero .card .image .btn-section {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(/assets/images/blogs/card-btn.png);
  background-size: cover;
}
.home-jumbotron-hero .card .image .btn-section .btn {
  width: 50%;
  height: 50%;
  background: var(--black);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-right: 15%;
}
.home-jumbotron-hero .card .image .btn-section .btn svg {
  fill: var(--white);
}
.home-jumbotron-hero .card .image img {
  width: 100%;
}
.home-jumbotron-hero .card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 1%, transparent 99%);
}
.home-jumbotron-hero .card .card-body .card-title {
  font-size: 18px;
  font-weight: 600;
  font-family: "PT Serif", serif;
  color: var(--white);
  margin-bottom: 10px;
}
.home-jumbotron-hero .card .card-body .card-text {
  font-size: 16px;
  font-weight: 400;
  color: var(--white);
}
.home-jumbotron-hero .card .card-body .card-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
  margin-bottom: 10px;
}
.home-jumbotron-hero .card .card-body .card-date span {
  color: var(--white);
  text-transform: lowercase;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}
.home-jumbotron-hero .card .card-body .card-date span::after {
  content: "";
  display: inline-flex;
  width: 5px;
  height: 5px;
  background: var(--white);
  border-radius: 10px;
}
.home-jumbotron-hero .card .card-body .tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}
.home-jumbotron-hero .card .card-body .tags .tag {
  border: 1px solid var(--white);
  color: var(--white);
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
  min-width: 100px;
  text-align: center;
}
.home-jumbotron-hero .intro-logos {
  display: grid;
  align-items: center;
  width: 100%;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 0;
}
.home-jumbotron-hero .intro-logos .intro-trusted-by {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
}
.home-jumbotron-hero .intro-logos svg {
  width: 120px;
  height: 60px;
}

.home-client-marquee-grid {
  width: 100%;
  height: 100%;
}
.home-client-marquee-grid .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  position: relative;
}
.home-client-marquee-grid .swiper-wrapper .client-marquee-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  width: 12%;
}
.home-client-marquee-grid .swiper-wrapper .client-marquee-item::before {
  content: "";
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.home-client-marquee-grid .swiper-wrapper .client-marquee-item img {
  width: 100%;
}
.home-client-marquee-grid .swiper-wrapper .client-marquee-item .client-marquee-item-tags {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 8px;
  margin-top: 4px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 9999;
}
.home-client-marquee-grid .swiper-wrapper .client-marquee-item .client-marquee-item-tags .client-marquee-item-tag {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  padding: 0 9px 2px;
  color: var(--white);
  background: var(--black);
  border-radius: 10px;
  font-size: 11px;
  white-space: nowrap;
}

.home-about-us-short {
  padding-top: 70px;
  padding-bottom: 30px;
}
.home-about-us-short .heading {
  padding: 30px;
}
.home-about-us-short .heading .main-title {
  color: var(--black);
  font-family: "PT Serif", serif;
  font-size: 50px;
  font-weight: 400;
}
.home-about-us-short .heading .main-title span {
  font-weight: 600;
  display: block;
}
.home-about-us-short .info {
  max-width: 70%;
}
.home-about-us-short .info p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}
.home-about-us-short .banner-section {
  column-gap: 30px;
  margin-top: 30px;
}
.home-about-us-short .banner-section .card {
  text-decoration: none;
  border: none;
  position: relative;
  cursor: default;
}
.home-about-us-short .banner-section .card .btn-section {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(/assets/images/services/card-btn.png);
  background-size: cover;
}
.home-about-us-short .banner-section .card .btn-section .btn {
  width: 50%;
  height: 50%;
  background: var(--black);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-right: 15%;
}
.home-about-us-short .banner-section .card .btn-section .btn svg {
  fill: var(--white);
}
.home-about-us-short .banner-section .card img {
  height: 100%;
  border-radius: 20px;
}
.home-about-us-short .banner-section .card.rect {
  min-width: calc(66.6666666667% - 20px);
}

.random-blogs-list {
  padding-bottom: 30px;
}
.random-blogs-list .heading {
  padding: 30px 0;
}
.random-blogs-list .heading .main-title {
  color: var(--black);
  font-family: "PT Serif", serif;
  font-size: 50px;
  font-weight: 400;
}
.random-blogs-list .heading .main-title span {
  font-weight: 600;
}
.random-blogs-list .banner-section {
  column-gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
}
.random-blogs-list .banner-section .card {
  text-decoration: none;
  border: none;
  min-width: calc(25% - 20px);
  max-width: calc(25% - 20px);
}
.random-blogs-list .banner-section .card .image {
  width: 100%;
  position: relative;
}
.random-blogs-list .banner-section .card .image .btn-section {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(/assets/images/services/card-btn.png);
  background-size: cover;
}
.random-blogs-list .banner-section .card .image .btn-section .btn {
  width: 50%;
  height: 50%;
  background: var(--black);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-right: 15%;
}
.random-blogs-list .banner-section .card .image .btn-section .btn svg {
  fill: var(--white);
}
.random-blogs-list .banner-section .card .image img {
  width: 100%;
  border-radius: 20px;
}
.random-blogs-list .banner-section .card .card-body .card-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 10px;
}
.random-blogs-list .banner-section .card .card-body .card-date span {
  color: var(--black);
  text-transform: lowercase;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}
.random-blogs-list .banner-section .card .card-body .card-date span::after {
  content: "";
  display: inline-flex;
  width: 5px;
  height: 5px;
  background: var(--black);
  border-radius: 10px;
}
.random-blogs-list .banner-section .card .card-body .card-title {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
  font-family: "PT Serif", serif;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.random-blogs-list .banner-section .card .card-body .card-text {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.home-service-us-short {
  padding-bottom: 30px;
}
.home-service-us-short .heading {
  padding: 30px;
}
.home-service-us-short .heading .main-title {
  color: var(--black);
  font-family: "PT Serif", serif;
  font-size: 50px;
  font-weight: 400;
}
.home-service-us-short .heading .main-title span {
  font-weight: 600;
  display: block;
}
.home-service-us-short .info {
  max-width: 70%;
}
.home-service-us-short .info p {
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
}
.home-service-us-short .banner-section {
  column-gap: 30px;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
}
.home-service-us-short .banner-section .card {
  text-decoration: none;
  border: none;
  min-width: calc(33.3333333333% - 20px);
  max-width: calc(33.3333333333% - 20px);
}
.home-service-us-short .banner-section .card .image {
  width: 100%;
  position: relative;
}
.home-service-us-short .banner-section .card .image .btn-section {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 25%;
  height: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(/assets/images/services/card-btn.png);
  background-size: cover;
}
.home-service-us-short .banner-section .card .image .btn-section .btn {
  width: 50%;
  height: 50%;
  background: var(--black);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-right: 15%;
}
.home-service-us-short .banner-section .card .image .btn-section .btn svg {
  fill: var(--white);
}
.home-service-us-short .banner-section .card .image img {
  width: 100%;
  border-radius: 20px;
}
.home-service-us-short .banner-section .card .card-body .card-title {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
  font-family: "PT Serif", serif;
  text-transform: uppercase;
}
.home-service-us-short .banner-section .card .card-body .card-title.odd {
  background: var(--white);
  padding: 10px 40px;
  margin: -38px auto;
  z-index: 9999999;
  position: relative;
  width: fit-content;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.home-service-us-short .banner-section .card .card-body .card-text {
  font-size: 16px;
  color: var(--black);
  font-weight: 400;
}

.home-testimonial {
  padding-bottom: 70px;
}
.home-testimonial .heading {
  padding: 30px;
}
.home-testimonial .heading .main-title {
  color: var(--black);
  font-family: "PT Serif", serif;
  font-size: 50px;
  font-weight: 400;
}
.home-testimonial .heading .main-title span {
  font-weight: 600;
  display: block;
}
.home-testimonial .reviews {
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%);
  margin-bottom: 15px;
  width: 100%;
  overflow: hidden;
}
.home-testimonial .reviews .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
.home-testimonial .reviews .swiper-wrapper .box {
  background: var(--white-lt);
  padding: 20px;
  border-radius: 10px;
}
.home-testimonial .reviews .swiper-wrapper .box i.fa.fa-quote-left {
  background: var(--black);
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 5px;
  margin-bottom: 10px;
}
.home-testimonial .reviews .swiper-wrapper .box .msg .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home-testimonial .reviews .swiper-wrapper .box .msg p {
  font-size: 14px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home-testimonial .reviews .swiper-wrapper .box .header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
  margin-top: auto;
}
.home-testimonial .reviews .swiper-wrapper .box .header .avatar {
  background: var(--black);
  display: flex;
  width: 40px;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: var(--white);
  border-radius: 40px;
}
.home-testimonial .reviews .swiper-wrapper .box .header .person .name {
  font-size: 14px;
}
.home-testimonial .reviews .swiper-wrapper .box .header .person .rate .fa {
  font-size: 14px;
}

.about-company-photos {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 99999;
}
.about-company-photos::before {
  content: "";
  background: var(--yellow-lt);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: -1;
}
.about-company-photos h4 {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.about-company-photos h2 {
  font-size: 40px;
  text-transform: lowercase;
  font-family: "PT Serif", serif;
  margin-bottom: 40px;
}
.about-company-photos .office-gallery {
  column-gap: 25px;
}
.about-company-photos .office-gallery .card {
  background: transparent;
  border-radius: 0;
  border: none;
  min-width: 60%;
}

.about-our-strengths h2 {
  font-size: 50px;
  font-family: "PT Serif", serif;
  font-weight: 600;
  margin-bottom: 100px;
}
.about-our-strengths .box {
  padding: 50px;
  padding-left: 15px;
}
.about-our-strengths .box:nth-child(odd) {
  padding-left: 50px;
}
.about-our-strengths .box h4 {
  font-size: 26px;
  font-family: "PT Serif", serif;
  font-weight: 500;
  margin-bottom: 30px;
}
.about-our-strengths .box p {
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-lt);
}

.contact-form form {
  padding-bottom: 30px;
}
.contact-form form h4 {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: "PT Serif", serif;
}
.contact-form form .btn-check-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.contact-form form .btn-check-options input {
  display: none;
}
.contact-form form .btn-check-options input:checked + .btn {
  background: var(--black);
  color: var(--white);
}
.contact-form form .btn-check-options .btn {
  border-radius: 50px;
  background: none;
  color: var(--black);
  border: 2px solid var(--black);
  padding: 10px 25px;
  font-size: 14px;
}
.contact-form form .btn-check-options .btn:hover {
  background: var(--dark-lt);
  color: var(--white);
}
.contact-form form .form-group {
  margin-bottom: 20px;
}
.contact-form form .form-group label {
  font-size: 18px;
  font-family: "PT Serif", serif;
}
.contact-form form .form-group textarea.form-control,
.contact-form form .form-group input.form-control {
  min-height: 60px;
  border-radius: 0;
  background: transparent;
  border: none;
  box-shadow: none !important;
  outline: none;
  color: var(--black);
  font-size: 18px;
  border-bottom: 2px solid var(--white-lt);
  padding-left: 0;
}
.contact-form form .form-group textarea.form-control:focus,
.contact-form form .form-group input.form-control:focus {
  border-bottom: 2px solid var(--dark-lt);
}
.contact-form form .btn-submit {
  border-radius: 50px;
  border: 2px solid var(--dark-lt);
  padding: 10px 25px;
  font-size: 14px;
  background: var(--dark-lt);
  color: var(--white);
}
.contact-form form .btn-submit svg {
  fill: var(--white);
}
.contact-form form .btn-submit:hover {
  background: var(--black);
}

.blogs-detail-view {
  margin-top: 60px;
  margin-bottom: 60px;
}
.blogs-detail-view > img {
  width: 100%;
}
.blogs-detail-view p {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  line-height: 30px;
}
.blogs-detail-view p::selection {
  color: var(--black);
  background: var(--yellow-lt);
}
.blogs-detail-view p span.bigword {
  float: left;
  font-size: 60px;
  padding: 0px 20px;
  line-height: 60px;
  font-weight: 700;
  font-family: "PT Serif", serif;
}
.blogs-detail-view p a {
  font-family: "PT Serif", serif;
  color: var(--blue-lt);
  text-decoration: none;
  font-weight: 700;
}
.blogs-detail-view h3 {
  font-size: 20px;
  margin: 30px 0;
  font-family: "PT Serif", serif;
  font-weight: 700;
}
.blogs-detail-view ol li,
.blogs-detail-view ul li {
  font-size: 14px;
}
.blogs-detail-view img.img-thumbnail {
  max-width: 50%;
  margin: 20px;
}
.blogs-detail-view a {
  font-family: "PT Serif", serif;
  color: var(--blue-lt);
  text-decoration: none;
  font-weight: 700;
}
.blogs-detail-view a:hover {
  color: var(--yellow-lt);
}
.blogs-detail-view .ads-banner {
  background: var(--black);
  border-radius: 0;
  border: none;
  margin-top: 40px;
  margin-bottom: 40px;
  position: relative;
}
.blogs-detail-view .ads-banner::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -100%;
  background: var(--black);
  z-index: -1;
}
.blogs-detail-view .ads-banner .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px;
}
.blogs-detail-view .ads-banner .card-body .card-title {
  color: var(--white);
  font-size: 22px;
  font-family: "PT Serif", serif;
  font-weight: 500;
}
.blogs-detail-view .ads-banner .card-body .card-text {
  color: var(--white);
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blogs-detail-view .ads-banner .card-body .card-link {
  color: var(--white);
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
}
.blogs-detail-view .ads-banner img {
  margin: -30px 0px;
  margin-right: 30px;
  border-radius: 10px;
  max-width: 20%;
}

.blogs-pagination-listing {
  margin-top: 60px;
  margin-bottom: 60px;
}
.blogs-pagination-listing .card-group {
  column-gap: 20px;
}
.blogs-pagination-listing .card-group .card {
  border-radius: 0;
  border: none;
  min-width: calc(25% - 15px);
  max-width: calc(25% - 15px);
}
.blogs-pagination-listing .card-group .card .image {
  width: 100%;
  position: relative;
}
.blogs-pagination-listing .card-group .card .image .btn-section {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(/assets/images/services/card-btn.png);
  background-size: cover;
}
.blogs-pagination-listing .card-group .card .image .btn-section .btn {
  width: 50%;
  height: 50%;
  background: var(--black);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
  margin-right: 15%;
}
.blogs-pagination-listing .card-group .card .image .btn-section .btn svg {
  fill: var(--white);
}
.blogs-pagination-listing .card-group .card .image img {
  width: 100%;
  border-radius: 10px;
}
.blogs-pagination-listing .card-group .card .card-body {
  padding: 15px 0;
}
.blogs-pagination-listing .card-group .card .card-body .card-title {
  font-size: 18px;
  font-weight: 600;
  font-family: "PT Serif", serif;
  color: var(--black);
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.blogs-pagination-listing .card-group .card .card-body .card-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.blogs-pagination-listing .card-group .card .card-body .card-date {
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 10px;
}
.blogs-pagination-listing .card-group .card .card-body .card-date span {
  color: var(--black);
  text-transform: lowercase;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}
.blogs-pagination-listing .card-group .card .card-body .card-date span::after {
  content: "";
  display: inline-flex;
  width: 5px;
  height: 5px;
  background: var(--black);
  border-radius: 10px;
}
.blogs-pagination-listing .card-group .card .card-body .tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}
.blogs-pagination-listing .card-group .card .card-body .tags .tag {
  border: 1px solid var(--black);
  color: var(--black);
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  min-width: 80px;
  text-align: center;
  white-space: nowrap;
}
.blogs-pagination-listing nav {
  margin-top: 30px;
}
.blogs-pagination-listing nav .pagination {
  column-gap: 10px;
}
.blogs-pagination-listing nav .pagination .page-item .page-link {
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  box-shadow: none;
  border-radius: 0;
}
.blogs-pagination-listing nav .pagination .page-item .page-link.active {
  background: var(--black);
  color: var(--white);
  border-color: var(--white);
}
.blogs-pagination-listing nav .pagination .page-item:first-child {
  margin-right: auto;
}
.blogs-pagination-listing nav .pagination .page-item:last-child {
  margin-left: auto;
}
.blogs-pagination-listing nav .pagination .page-item:first-child .page-link, .blogs-pagination-listing nav .pagination .page-item:last-child .page-link {
  border: none;
  display: flex;
  column-gap: 10px;
}
.blogs-pagination-listing nav .pagination .page-item.disabled .page-link {
  background: var(--white);
}

.portfolio-items .block {
  column-count: 3;
  flex-wrap: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: flex-start;
  justify-content: space-between;
  column-gap: 3.69%;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
}
.portfolio-items .block .portfolio-item {
  width: 30%;
  text-align: left;
  margin-top: 0;
}
.portfolio-items .block .portfolio-item:nth-child(3n+1), .portfolio-items .block .portfolio-item:nth-child(3n+3) {
  margin-top: 60px;
}
.portfolio-items .block .portfolio-item .portfolio-link {
  color: var(--black);
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  position: relative;
}
.portfolio-items .block .portfolio-item .portfolio-link img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border-radius: 15px;
}
.portfolio-items .block .portfolio-item .portfolio-link .portfolio-flex {
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.portfolio-items .block .portfolio-item .portfolio-link .portfolio-flex .portfolio-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
}
.portfolio-items .block .portfolio-item .portfolio-link .portfolio-flex .tag {
  color: var(--black);
  text-transform: uppercase;
  background-color: var(--yellow-lt);
  border-radius: 50px;
  margin-right: 20px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  font-family: "PT Serif", serif;
}

.thankyoucontent {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: var(--black);
  align-items: center;
  justify-content: center;
}
.thankyoucontent .fa {
  color: var(--white);
  font-size: 180px;
}
.thankyoucontent h1 {
  font-family: "PT Serif", serif;
  font-size: 40px;
  font-weight: 400;
  color: var(--white);
  margin: 30px 0;
}
.thankyoucontent p {
  margin: 0;
  font-size: 16px;
  color: var(--white);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.thankyoucontent .go-home {
  font-family: "PT Serif", serif;
  border: none;
  color: var(--white);
  white-space: nowrap;
  padding: 8px 20px;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  z-index: 11;
  transition: all 0.2s;
  text-decoration: none;
  margin-top: 40px;
}
.thankyoucontent .go-home .fa {
  font-size: 18px;
  margin-left: 15px;
}
.thankyoucontent .go-home::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
  border: 1px solid var(--white-lt);
  background: var(--black);
  z-index: -1;
}
.thankyoucontent .go-home::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  right: 0px;
  border: 1px solid var(--white-lt);
  z-index: -2;
}
.thankyoucontent .go-home:hover {
  transform: translate(-4px, -4px);
}
.thankyoucontent .go-home:hover::after {
  transform: translate(4px, 4px);
}

.footer-connect-us {
  background: var(--black);
  overflow: hidden;
}
.footer-connect-us .design .design-first {
  top: -10px;
  left: -15px;
}
.footer-connect-us .design .design-last {
  top: -10px;
  right: -15px;
}
.footer-connect-us .design svg path {
  fill: var(--white-lt);
  fill-opacity: 0.2;
}
.footer-connect-us .middle {
  padding-top: 150px;
  padding-bottom: 150px;
}
.footer-connect-us .middle h2 {
  font-size: 45px;
  font-family: "PT Serif", serif;
}
.footer-connect-us .middle .btn {
  color: var(--black);
  border-color: var(--white-lt);
  background-color: var(--white-lt);
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 500;
  font-family: "PT Serif", serif;
}

.footer {
  background: var(--white-lt);
  padding: 50px 15px;
}
.footer h2 {
  font-size: 50px;
  font-family: "PT Serif", serif;
  font-weight: 600;
  margin-bottom: 20px;
}
.footer .mailto {
  margin-bottom: 50px;
}
.footer .mailto a {
  font-size: 16px;
  column-gap: 10px;
  text-decoration: none;
}
.footer .mailto a svg {
  width: 28px;
  height: 28px;
}
.footer .mailto a:hover svg {
  transform: rotate(-45deg);
}
.footer .address .title {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.footer .address a {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: var(--dark-lt);
  display: block;
  line-height: 26px;
}
.footer .nav {
  flex-direction: column;
}
.footer .nav .links {
  font-size: 14px;
  color: var(--black);
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 15px;
  white-space: nowrap;
}

@media (max-width: 1400px) {
  .home-jumbotron-hero h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
  .home-jumbotron-hero h1 span,
  .home-jumbotron-hero h1 i {
    font-size: 70px;
  }
  .home-jumbotron-hero h1 .bigletter {
    top: -5px;
    left: 104px;
    font-size: 110px;
  }
  .home-about-us-short {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .home-about-us-short .heading .main-title {
    font-size: 40px;
  }
  .home-about-us-short .info {
    max-width: 60%;
  }
  .home-about-us-short .info p {
    font-size: 16px;
  }
  .home-about-us-short .info .industries {
    max-width: 100%;
  }
  .home-service-us-short {
    padding-bottom: 60px;
  }
  .home-service-us-short .heading .main-title {
    font-size: 40px;
  }
  .home-service-us-short .info p {
    font-size: 16px;
  }
  .random-blogs-list {
    padding-bottom: 60px;
  }
  .random-blogs-list .heading .main-title {
    font-size: 40px;
  }
  .home-testimonial {
    padding-bottom: 60px;
  }
  .home-testimonial .heading .main-title {
    font-size: 40px;
  }
}
@media (max-width: 1200px) {
  .home-jumbotron-hero h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
  .home-jumbotron-hero h1 span,
  .home-jumbotron-hero h1 i {
    font-size: 50px;
  }
  .home-jumbotron-hero h1 .bigletter {
    display: none;
  }
  .home-jumbotron-hero .total-client {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }
  .home-jumbotron-hero .total-client .sub-line {
    padding: 15px;
    max-width: 100%;
    text-align: center;
  }
  .about-company-photos {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about-company-photos h2 {
    font-size: 30px;
  }
  .about-our-strengths h2 {
    font-size: 40px;
    margin-bottom: 70px;
  }
  .about-our-strengths .box {
    padding: 40px;
  }
  .about-our-strengths .box:nth-child(odd) {
    padding-left: 40px;
  }
}
@media (min-width: 992px) {
  .top-bar {
    z-index: 999999;
    background: var(--yellow-lt);
  }
  .top-bar .navbar-brand {
    margin: 10px 0;
  }
  .top-bar .navbar-brand span {
    font-family: "Pacifico", cursive;
    font-size: 22px;
    color: var(--black);
  }
  .top-bar .navbar-brand img {
    display: none;
  }
  .top-bar .navbar-brand sup {
    font-size: 16px;
    color: var(--dark-lt);
    margin-left: 5px;
  }
  .top-bar .navbar-brand i {
    font-size: 16px;
    font-family: "Pacifico", cursive;
    font-style: normal;
  }
  .top-bar .navbar-nav.menus {
    margin-left: 50px;
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link {
    z-index: 9999;
    color: var(--dark);
    font-weight: 600;
    font-size: 14px;
    padding: 0px;
    max-height: 44px;
    position: relative;
    overflow: hidden;
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link::before,
  .top-bar .navbar-nav.menus .nav-item .nav-link span {
    padding: 10px 20px;
    justify-content: flex-start;
    display: flex;
    white-space: nowrap;
    -webkit-transition: transform 0.5s ease-out 0.1s;
    transition: transform 0.5s ease-out 0.1s;
    -webkit-filter: blur(0);
    filter: blur(0);
    cursor: pointer;
    font-family: "mona-sans";
    font-weight: 600;
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link::before {
    content: attr(data-hover-text);
    color: var(--dark);
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--dark-lt);
    border: none;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-110%, -50%);
    -ms-transform: translate(-110%, -50%);
    transform: translate(-110%, -50%);
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link.active::after, .top-bar .navbar-nav.menus .nav-item .nav-link:hover::after {
    -webkit-transform: translate(102%, -50%);
    -ms-transform: translate(102%, -50%);
    transform: translate(102%, -50%);
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link.active::before, .top-bar .navbar-nav.menus .nav-item .nav-link:hover::before {
    -webkit-transform: translateY(-44px);
    -ms-transform: translateY(-44px);
    transform: translateY(-44px);
  }
  .top-bar .navbar-nav.menus .nav-item .nav-link.active span, .top-bar .navbar-nav.menus .nav-item .nav-link:hover span {
    -webkit-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown {
    position: unset;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown.active .dropdown-menu.show,
  .top-bar .navbar-nav.menus .nav-item.dropdown.active .dropdown-menu, .top-bar .navbar-nav.menus .nav-item.dropdown:hover .dropdown-menu.show,
  .top-bar .navbar-nav.menus .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    top: 66px;
    margin-top: var(--bs-dropdown-spacer);
    z-index: 1111;
    transition: top 0.3s;
    transition-delay: 0.7s;
    min-height: calc(100vh - 68px);
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown.active .dropdown-menu.show .container .drop-items .drop-item,
  .top-bar .navbar-nav.menus .nav-item.dropdown.active .dropdown-menu .container .drop-items .drop-item, .top-bar .navbar-nav.menus .nav-item.dropdown:hover .dropdown-menu.show .container .drop-items .drop-item,
  .top-bar .navbar-nav.menus .nav-item.dropdown:hover .dropdown-menu .container .drop-items .drop-item {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
    transition: 0.3s ease 0s;
    opacity: 1;
    transition-delay: 1s;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu {
    background: var(--yellow-lt);
    border: none;
    --bs-dropdown-min-width: 100%;
    left: 0;
    display: block;
    top: -1000px;
    transition: top 0.8s;
    z-index: -1;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu.show {
    display: none;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .header {
    padding: 20px 15px;
    margin-bottom: 15px;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .header .title {
    font-family: "mona-sans";
    font-size: 14px;
    font-weight: 600;
    padding: 10px;
    text-transform: uppercase;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .header .para {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    text-transform: lowercase;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 15px;
    flex-wrap: wrap;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item {
    display: flex;
    flex-direction: column;
    max-width: calc(20% - 15px);
    min-width: calc(20% - 15px);
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    text-decoration: none;
    -webkit-transform: rotateY(45deg);
    transform: rotateY(45deg);
    opacity: 0;
    transition: opacity 50ms ease 0.3s, transform 50ms ease 0.3s;
    transition-delay: 0.7s;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .image {
    width: 100%;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .image img {
    width: 100%;
    border-radius: 5px;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .image:hover img {
    box-shadow: 0 0 0 5px #fff, 0 0 6px 6px rgba(0, 0, 0, 0.09);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .drop-body {
    padding: 10px;
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .drop-body .drop-title {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    font-family: "mona-sans";
  }
  .top-bar .navbar-nav.menus .nav-item.dropdown .dropdown-menu .container .drop-items .drop-item .drop-body .drop-text {
    font-size: 14px;
    font-weight: 500;
    text-transform: lowercase;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .top-bar .navbar-nav.socials {
    column-gap: 10px;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link i {
    color: var(--black);
    font-size: 20px;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn {
    border: none;
    color: var(--black);
    white-space: nowrap;
    padding: 8px 20px;
    border-radius: 0px;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    z-index: 11;
    transition: all 0.2s;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    right: 0px;
    border: 1px solid var(--dark-lt);
    background: var(--yellow-lt);
    z-index: -1;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0px;
    right: 0px;
    border: 1px solid var(--dark-lt);
    z-index: -2;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn:hover {
    transform: translate(-4px, -4px);
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn:hover::after {
    transform: translate(4px, 4px);
  }
}
@media (max-width: 992px) {
  body {
    padding-top: 60px;
  }
  .top--section {
    min-height: calc(100vh - 60px);
    justify-content: space-around;
  }
  .top--section .container.mt-auto {
    margin-top: unset !important;
  }
  .top-bar {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    border-bottom: 1px solid #b3b3b3;
    z-index: 9999999;
    background: var(--yellow-lt);
  }
  .top-bar.in {
    background: var(--yellow-lt);
  }
  .top-bar > .container {
    justify-content: flex-start;
    column-gap: 10px;
    max-width: 100%;
  }
  .top-bar .navbar-brand {
    margin: 0;
  }
  .top-bar .navbar-brand img {
    display: flex;
    width: 35px;
  }
  .top-bar .navbar-brand span {
    display: none;
  }
  .top-bar .navbar-toggler {
    padding: 0px;
    border: none;
    box-shadow: none;
  }
  .top-bar .navbar-toggler .bar {
    width: 25px;
    height: 2px;
    background-color: var(--black);
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition: 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  .top-bar .navbar-toggler .bar:last-child {
    width: 18px;
  }
  .top-bar .navbar-toggler:not(.collapsed) .bar1 {
    transform: translate(4px, 18px) rotate(-45deg);
  }
  .top-bar .navbar-toggler:not(.collapsed) .bar3 {
    transform: translate(4px, -16px) rotate(45deg);
    width: 25px;
  }
  .top-bar .navbar-toggler:not(.collapsed) .bar2 {
    opacity: 0;
  }
  .top-bar .navbar-collapse {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start;
    position: fixed;
    top: 61px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999999;
    background: var(--yellow-lt);
    margin-left: -1000px;
    transition: margin-left 0.5s;
  }
  .top-bar .navbar-nav.socials {
    flex-direction: row;
    margin-left: auto;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link {
    font-size: 12px;
    padding: 8px;
    margin: 0px 3px;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link i {
    font-size: 20px;
  }
  .top-bar .navbar-nav.socials .nav-item .nav-link.btn {
    display: none;
  }
  .top-bar .navbar-collapse.collapsing,
  .top-bar .navbar-collapse.show {
    margin-left: 0;
    transition: margin-left 0.5s;
    height: calc(100vh - 62px) !important;
    overflow-x: auto;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus,
  .top-bar .navbar-collapse.show .navbar-nav.menus {
    margin: 0;
    width: 100%;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .nav-link,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .nav-link {
    padding: 8px 15px;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .nav-link span,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .nav-link span {
    font-size: 14px;
    font-family: "mona-sans";
    font-weight: 600;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .nav-link::after,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .nav-link::after {
    display: none;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu {
    border-radius: 0;
    display: block;
    padding: 0;
    border: none;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container {
    max-width: 100%;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .header,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .header {
    display: none !important;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: var(--yellow-lt);
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .image,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .image {
    min-width: 80px;
    max-width: 80px;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .image img,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .image img {
    width: 100%;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body {
    padding-left: 10px;
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body .drop-title,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body .drop-title {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    font-family: "mona-sans";
  }
  .top-bar .navbar-collapse.collapsing .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body .drop-text,
  .top-bar .navbar-collapse.show .navbar-nav.menus .nav-item .dropdown-menu > .container .drop-items .drop-item .drop-body .drop-text {
    font-size: 14px;
    font-weight: 500;
    text-transform: lowercase;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .home-jumbotron-hero {
    align-items: center;
    min-height: unset;
  }
  .home-jumbotron-hero h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .home-jumbotron-hero h1 i.fa {
    font-size: 20px;
  }
  .home-jumbotron-hero .sub-line {
    text-align: justify;
    font-size: 14px;
    font-weight: 500;
    max-width: 100%;
    margin: 10px auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .home-jumbotron-hero .intro-logos {
    grid-auto-rows: -webkit-min-content;
    grid-auto-rows: min-content;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    justify-items: center;
    max-width: 100%;
    grid-auto-flow: row;
    padding: 10px 0;
  }
  .home-jumbotron-hero .intro-logos .intro-trusted-by {
    grid-column: span 2;
  }
  .home-about-us-short {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-about-us-short > .d-flex {
    flex-direction: column !important;
  }
  .home-about-us-short .banner-section {
    flex-direction: column-reverse !important;
    row-gap: 20px;
    align-items: center;
  }
  .home-about-us-short .banner-section .card {
    min-width: 100%;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
  }
  .home-about-us-short .banner-section .card.rect {
    min-width: 100%;
  }
  .home-about-us-short .heading {
    padding: 0;
  }
  .home-about-us-short .heading .main-title {
    font-size: 30px;
    text-align: center;
  }
  .home-about-us-short .info {
    max-width: 100%;
    padding-top: 15px;
  }
  .home-about-us-short .info p {
    font-size: 14px;
  }
  .home-testimonial {
    padding-bottom: 0px;
  }
  .home-testimonial .heading .main-title {
    font-size: 30px;
  }
  .home-service-us-short {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .home-service-us-short > .d-flex {
    flex-direction: column !important;
  }
  .home-service-us-short .banner-section {
    flex-direction: row !important;
    column-gap: 15px;
  }
  .home-service-us-short .banner-section .card {
    min-width: calc(33.3333333333% - 10px);
    max-width: calc(33.3333333333% - 10px);
  }
  .home-service-us-short .banner-section .card .image .btn-section {
    width: 37%;
    height: 37%;
  }
  .home-service-us-short .banner-section .card .card-body .card-title {
    font-size: 14px;
  }
  .home-service-us-short .banner-section .card .card-body .card-title.odd {
    padding: 10px 25px;
    margin: -35px auto;
  }
  .home-service-us-short .banner-section .card .card-body .card-text {
    font-size: 12px;
  }
  .home-service-us-short .heading {
    padding: 0;
  }
  .home-service-us-short .heading .main-title {
    font-size: 30px;
    text-align: center;
  }
  .home-service-us-short .info {
    max-width: 100%;
    padding-top: 15px;
  }
  .home-service-us-short .info p {
    font-size: 14px;
  }
  .random-blogs-list {
    padding-top: 30px;
    padding-bottom: 0px;
  }
  .random-blogs-list > .d-flex {
    flex-direction: column !important;
  }
  .random-blogs-list .banner-section {
    flex-direction: row !important;
    column-gap: 15px;
  }
  .random-blogs-list .banner-section .card {
    min-width: calc(33.3333333333% - 10px);
    max-width: calc(33.3333333333% - 10px);
  }
  .random-blogs-list .banner-section .card .image .btn-section {
    width: 37%;
    height: 37%;
  }
  .random-blogs-list .banner-section .card .card-body .card-title {
    font-size: 14px;
  }
  .random-blogs-list .banner-section .card .card-body .card-text {
    font-size: 12px;
  }
  .random-blogs-list .heading {
    padding: 0;
  }
  .random-blogs-list .heading .main-title {
    font-size: 30px;
    text-align: center;
  }
  .footer-connect-us .middle {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .footer-connect-us .middle h2 {
    font-size: 35px;
    margin-bottom: 20px !important;
  }
  .about-company-photos {
    padding-top: 50px;
  }
  .about-company-photos h2 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .about-company-photos h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .about-our-strengths {
    margin-bottom: 40px;
  }
  .about-our-strengths h2 {
    font-size: 30px;
    margin-bottom: 50px;
  }
  .about-our-strengths .box {
    padding: 20px;
    padding-left: 10px;
    padding-bottom: 0px;
  }
  .about-our-strengths .box:nth-child(odd) {
    padding-left: 10px;
  }
  .about-our-strengths .box h4 {
    margin-bottom: 10px;
  }
  .about-our-strengths .box p {
    margin-bottom: 0;
  }
  .blogs-pagination-listing .card-group .card {
    min-width: calc(33.3333333333% - 15px);
    max-width: calc(33.3333333333% - 15px);
  }
  .footer .nav .links {
    padding: 0px 15px;
  }
  .portfolio-items .block {
    column-count: 2;
    row-gap: 30px;
    margin-top: 30px;
  }
  .portfolio-items .block .portfolio-item {
    width: 47%;
  }
  .portfolio-items .block .portfolio-item:nth-child(3n+1), .portfolio-items .block .portfolio-item:nth-child(3n+3) {
    margin-top: 0px;
  }
  .portfolio-items .block .portfolio-item .portfolio-link .portfolio-flex .portfolio-title {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 768px) {
  .footer {
    padding: 30px 15px;
  }
  .footer h2 {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .footer .mailto {
    margin-bottom: 20px;
  }
  .footer .sub-details {
    flex-direction: column-reverse;
  }
  .footer .sub-details .address .title {
    margin-bottom: 10px !important;
    line-height: 18px;
  }
  .footer .sub-details .nav {
    margin-left: unset !important;
    margin-bottom: 20px;
    max-width: 50%;
  }
  .footer .sub-details .nav .links {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .footer-connect-us .middle {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .footer-connect-us .middle h2 {
    font-size: 28px;
    margin-bottom: 20px !important;
  }
  .footer-connect-us .middle .btn {
    display: block;
    width: 100%;
  }
  .home-about-us-short .heading .main-title {
    font-size: 20px;
  }
  .home-testimonial .heading {
    padding: 15px;
  }
  .home-testimonial .heading .main-title {
    font-size: 20px;
  }
  .home-testimonial .reviews .swiper-wrapper .box i.fa.fa-quote-left {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .home-testimonial .reviews .swiper-wrapper .box .msg .title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .home-testimonial .reviews .swiper-wrapper .box .msg p {
    font-size: 12px;
  }
  .home-testimonial .reviews .swiper-wrapper .box .header .avatar {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .home-testimonial .reviews .swiper-wrapper .box .header .person .name {
    font-size: 12px;
  }
  .home-testimonial .reviews .swiper-wrapper .box .header .person .rate .fa {
    font-size: 12px;
  }
  .home-service-us-short .banner-section {
    flex-wrap: wrap;
  }
  .home-service-us-short .banner-section .card {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .home-service-us-short .banner-section .card.odd {
    flex-direction: column-reverse;
  }
  .home-service-us-short .banner-section .card .card-body {
    padding: 10px 5px;
  }
  .home-service-us-short .banner-section .card .card-body .card-title {
    font-size: 14px;
  }
  .home-service-us-short .banner-section .card .card-body .card-title.odd {
    padding: 10px 25px;
    white-space: nowrap;
  }
  .home-service-us-short .banner-section .card .card-body .card-text {
    font-size: 12px;
  }
  .home-service-us-short .heading .main-title {
    font-size: 20px;
  }
  .random-blogs-list .banner-section {
    flex-wrap: wrap;
  }
  .random-blogs-list .banner-section .card {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  .random-blogs-list .banner-section .card .card-body {
    padding: 10px 5px;
  }
  .random-blogs-list .banner-section .card .card-body .card-title {
    font-size: 14px;
  }
  .random-blogs-list .banner-section .card .card-body .card-text {
    font-size: 12px;
  }
  .random-blogs-list .heading .main-title {
    font-size: 20px;
  }
  .home-jumbotron-hero {
    padding: 10px;
  }
  .home-jumbotron-hero h1 {
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .home-jumbotron-hero h1 span,
  .home-jumbotron-hero h1 i {
    font-size: 28px;
  }
  .home-jumbotron-hero .intro-logos {
    row-gap: 5px;
  }
  .home-jumbotron-hero .intro-logos svg {
    width: 100px;
    height: 50px;
  }
  .home-jumbotron-hero .card .card-body {
    position: unset;
    background: transparent;
  }
  .home-jumbotron-hero .card .card-body .card-date,
  .home-jumbotron-hero .card .card-body .card-date span,
  .home-jumbotron-hero .card .card-body .card-text,
  .home-jumbotron-hero .card .card-body .card-title {
    color: var(--black);
  }
  .home-jumbotron-hero .card .card-body .card-title {
    font-size: 16px;
  }
  .home-jumbotron-hero .card .card-body .card-text {
    font-size: 14px;
  }
  .home-jumbotron-hero .card .card-body .tags .tag {
    color: var(--black);
    border-color: var(--black);
  }
  .about-company-photos {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .about-company-photos h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .about-company-photos h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .about-our-strengths h2 {
    font-size: 26px;
    margin-bottom: 30px;
  }
  .about-our-strengths .box h4 {
    font-size: 18px;
  }
  .about-our-strengths .box p {
    font-size: 14px;
  }
  .contact-form form h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .contact-form form .btn-check-options {
    column-gap: 10px;
    row-gap: 10px;
  }
  .contact-form form .btn-check-options .btn {
    padding: 6px 18px;
    font-size: 12px;
  }
  .contact-form form .form-group {
    margin-bottom: 15px;
  }
  .contact-form form .form-group label {
    font-size: 14px;
  }
  .contact-form form .form-group input.form-control {
    height: 40px;
    font-size: 14px;
  }
  .contact-form form .btn-submit {
    padding: 6px 18px;
    font-size: 12px;
  }
  .no-xs-height,
  .min-height-70 {
    min-height: 30vh;
  }
  .blogs-pagination-listing .card-group .card {
    min-width: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .blogs-detail-view .ads-banner {
    margin-top: 100px;
  }
  .blogs-detail-view .ads-banner::after {
    display: none;
  }
  .blogs-detail-view .ads-banner .d-flex {
    flex-direction: column-reverse;
  }
  .blogs-detail-view .ads-banner .d-flex img {
    max-width: 50%;
    margin: -60px auto;
    margin-bottom: 0px;
  }
  .blogs-detail-view .content {
    padding: 0 15px !important;
  }
  .portfolio-items .block {
    column-count: 1;
    margin-bottom: 30px;
  }
  .portfolio-items .block .portfolio-item {
    width: 100%;
  }
  .portfolio-items .block .portfolio-item .portfolio-link img {
    width: 100%;
  }
  .blogs-detail-view img.img-thumbnail {
    max-width: 100%;
    margin: 0px;
  }
}
@media (max-width: 576px) {
  .about-company-photos {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .about-company-photos h2 {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .about-company-photos h4 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .about-our-strengths h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .blogs-pagination-listing {
    padding: 0 15px;
  }
  .blogs-pagination-listing .card-group .card {
    min-width: 100%;
    max-width: 100%;
  }
  .blogs-pagination-listing .card-group {
    padding: 0 15px;
  }
}